import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { NavLink, NavLinkProps, useLocation } from "react-router-dom";

import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton } from "primereact/skeleton";

import { useFacility } from "../hooks/swr/useFacility";
import { useGeneralActivity } from "../hooks/swr/useGeneralActivity";
import { useIsAdmin } from "../hooks/swr/useIsAdmin";
import { useSeries } from "../hooks/swr/useSeries";
import { useSeriesMatchInfo } from "../hooks/swr/useSeriesMatchInfo";
import { useUser } from "../hooks/swr/useUser";

import { ProfileImageWithFallback } from "./ProfileImageWithFallback";

export type CrumbText = React.ReactNode | Promise<React.ReactNode>;

interface Breadcrumb {
  to: NavLinkProps["to"];
  text: CrumbText;
  title: CrumbText;
}

export const Breadcrumbs = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  return (
    <nav className="mt-6 flex items-center gap-2.5 px-4 leading-none md:mt-8 lg:mt-10 2xl:px-0">
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={breadcrumb.to.toString()}>
          <Crumb {...breadcrumb} />

          {index !== breadcrumbs.length - 1 && (
            <FontAwesomeIcon
              icon={faChevronRight}
              className="text-gray-700"
              size="xs"
            />
          )}
        </React.Fragment>
      ))}
    </nav>
  );
};

const Crumb = ({ to, text, title }: Breadcrumb) => {
  if (typeof text !== "string") {
    return <Skeleton width="6rem" height=".875rem" />;
  }

  return (
    <NavLink
      to={to}
      className="text-sm font-medium text-gray-700 last:font-semibold last:text-primary hover:text-primary"
      title={typeof title === "string" ? title : text}
    >
      {text}
    </NavLink>
  );
};

const GET_TEXT: Record<
  string,
  string | { text: string; title: React.ReactNode }
> = {
  players: {
    text: "navigation.menuitem.players",
    title: "admin.players-overview",
  },
  settings: "navigation.menuitem.settings",
  activities: "navigation.menuitem.activity",
  matches: "common.games",
  series: "common.serie",
  scoreboard: "activity.scoreboard",
  reschedule: "games.reschedule",
  venues: {
    text: "navigation.menuitem.venues",
    title: "venues.find-venue",
  },
  superadmin: "navigation.menuitem.superAdmin",
  payment: "payment.confirm",
  "payment/confirmed": "payment.complete",
  "booking/invitation": "booking-invitation.greetings-title",
  "event/new": "admin.activities.event.create",
  booking: "navigation.menuitem.booking",
  me: "navigation.menuitem.myprofile",
  "friends/invitation": "common.friend-request",
};

const isGuid = (guid: string) => {
  return guid.length === 36 && guid.match(/-/g)?.length === 4;
};

export const useBreadcrumbs = (): Breadcrumb[] => {
  const location = useLocation();
  const isAdmin = useIsAdmin();
  const intl = useIntl();

  const [seriesId, setSeriesId] = useState("");
  const { series } = useSeries(seriesId, "participants", {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const [eventId, setEventId] = useState("");
  const { activity: event } = useGeneralActivity(
    eventId !== "new" ? eventId : null,
    "",
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const [userId, setUserId] = useState("");
  const { user } = useUser(userId, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const [facilityId, setFacilityId] = useState("");
  const { facility } = useFacility(facilityId, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const [matchId, setMatchId] = useState("");
  const { matchInfo } = useSeriesMatchInfo(seriesId, matchId, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return useMemo(() => {
    const parts = location.pathname.split("/").filter(Boolean);

    const breadcrumbs = parts
      .map((part, index) => {
        let getTextKey = part;

        const path = `/${parts.slice(0, index + 1).join("/")}`;

        const breadcrumb: Breadcrumb = {
          to: path,
          text: "",
          title: "",
        };

        if (isGuid(part)) {
          getTextKey = `${parts.at(index - 1)}/[id]`;

          let text: CrumbText = "";
          let title: CrumbText = "";

          switch (getTextKey) {
            case "serie/[id]":
            case "series/[id]": {
              setSeriesId(part);
              text = series?.name ?? Promise.resolve("");
              break;
            }

            case "event/[id]": {
              setEventId(part);
              text = event?.name ?? Promise.resolve("");
              break;
            }

            case "match/[id]": {
              setMatchId(part);
              const homeTeam = matchInfo?.homeTeam?.team?.players
                ?.map(p => p?.lastName)
                .join(" & ");
              const opponentTeam = matchInfo?.opponentTeam?.team?.players
                ?.map(p => p?.lastName)
                .join(" & ");
              text =
                intl.formatMessage(
                  { id: "breadcrumbs.series.teams.match-title" },
                  { homeTeam, opponentTeam },
                ) ?? Promise.resolve("");
              break;
            }

            case "profile/[id]": {
              setUserId(part);
              text = user?.displayName ?? Promise.resolve("");
              break;
            }

            case "players/[id]": {
              setUserId(part);

              text = user?.displayName ?? Promise.resolve("");

              title = user?.displayName ? (
                <div className="flex items-center gap-2 xl:gap-4">
                  <ProfileImageWithFallback
                    className="md:h-12 md:w-12 xl:h-16 xl:w-16"
                    hasImage={!!user?.profileImage}
                    src={user?.profileImage}
                    firstName={user?.firstName}
                    lastName={user?.lastName}
                  />
                  {user?.displayName}
                </div>
              ) : (
                Promise.resolve("")
              );

              break;
            }

            case "venues/[id]":
            case "facility/[id]": {
              setFacilityId(part);
              text = facility?.name ?? Promise.resolve("");
              break;
            }

            case "scoreboard/[id]": {
              text =
                series?.divisions?.find(d => d.id === part)?.name ??
                Promise.resolve("");
              break;
            }
          }

          breadcrumb.text = text;
          breadcrumb.title = title || text;
        } else {
          /*
            We check for part/nextPart and previousPart/part to determine
            if we should show the breadcrumb or not.
            Eg. payment/confirmed should only be matched together and shown once.

            Add a path thats the rest of the parts to include the payment id etc.
          */

          const partWithNext = `${part}/${parts.at(index + 1)}`;
          const partWithPrevious = `${parts.at(index - 1)}/${part}`;

          if (GET_TEXT[partWithNext]) {
            breadcrumb.to = `/${parts.slice(index).join("/")}`;
            getTextKey = partWithNext;
          }

          if (GET_TEXT[partWithPrevious]) {
            return breadcrumb;
          }

          const text = GET_TEXT[getTextKey];

          if (!text) {
            return breadcrumb;
          }

          if (typeof text === "function") {
            breadcrumb.text = text;
            breadcrumb.title = breadcrumb.text;
          } else if (typeof text === "string") {
            breadcrumb.text = intl.formatMessage({ id: text });
            breadcrumb.title = breadcrumb.text;
          } else if (typeof text === "object" && "text" in text) {
            breadcrumb.text =
              typeof text.text === "function"
                ? text.text
                : typeof text.text === "string"
                  ? intl.formatMessage({ id: text.text })
                  : text.text;

            breadcrumb.title =
              typeof text.title === "function"
                ? text.title
                : typeof text.title === "string"
                  ? intl.formatMessage({ id: text.title })
                  : text.title;
          } else {
            breadcrumb.text = text;
            breadcrumb.title = text;
          }
        }

        return breadcrumb;
      })
      .filter(obj => !!obj.text);

    if (isAdmin) {
      breadcrumbs.splice(0, 0, {
        to: "/admin",
        text: intl.formatMessage({ id: "navigation.menuitem.home" }),
        title: "",
      });
    } else if (location.pathname === "/" || breadcrumbs.length > 0) {
      breadcrumbs.splice(0, 0, {
        to: "/",
        text: intl.formatMessage({ id: "navigation.menuitem.home" }),
        title: intl.formatMessage({ id: "common.welcome-to-court22" }),
      });
    }

    return breadcrumbs;
  }, [
    location.pathname,
    isAdmin,
    series?.name,
    series?.divisions,
    event?.name,
    matchInfo?.homeTeam?.team?.players,
    matchInfo?.opponentTeam?.team?.players,
    intl,
    user?.displayName,
    user?.profileImage,
    user?.firstName,
    user?.lastName,
    facility?.name,
  ]);
};
