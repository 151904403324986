import useSWR, { SWRConfiguration } from "swr";

import type { OpenBooking } from "../../models/OpenBookings";

import { getOpenBooking } from "../../services/facilityOpenBookingsService";

export const useOpenBooking = (
  facilityId: OpenBooking["facilityId"] | undefined,
  openBookingId: OpenBooking["id"] | undefined,
  options?: SWRConfiguration,
) => {
  const { data, ...rest } = useSWR(
    openBookingId && facilityId
      ? ["openBooking", facilityId, openBookingId]
      : null,
    ([, facilityId, openBookingId]) =>
      getOpenBooking(facilityId, openBookingId),
    options,
  );

  return {
    ...rest,
    openBooking: data,
  };
};
