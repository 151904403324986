import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

import { useScript } from "usehooks-ts";

import type { Order } from "../../../models/Order";
import type {
  BookingOrderItem,
  EventOrderItem,
  MembershipOrderItem,
  OpenBookingOrderItem,
  SeriesOrderItem,
} from "../../../models/OrderItem";
import {
  type PaymentMethod,
  PaymentMethodStatus,
} from "../../../models/payment";
import type { SwedbankPayEligbleCheckInstrument } from "../../../models/swedbankPayCheckout";
import type { IBooking } from "../../../modules/checkout/models/Booking";
import type { GeneralActivities } from "../../../modules/game/models/GeneralActivities";
import type { Series } from "../../../modules/game/models/Series";
import type { Membership } from "../../../modules/player/models/Membership";
import type { User } from "../../../modules/player/models/User";

import { useToaster } from "../../../hooks/common/useToaster";

import { getBooking } from "../../../modules/checkout/services/Booking";
import { getGeneralActivity } from "../../../modules/game/services/GeneralActivities";
import { getSeries } from "../../../modules/game/services/Serie";
import { getMembership } from "../../../modules/player/services/MembershipService";
import { getOpenBooking } from "../../../services/myOpenBookingsService";
import { getOrderPaymentMethods } from "../../../services/myOrdersService";

if (!process.env.REACT_APP_SWEBANK_PAY_ELIGBLE_CHECK_SCRIPT_URL) {
  throw new Error(
    "REACT_APP_SWEBANK_PAY_ELIGBLE_CHECK_SCRIPT_URL is not defined",
  );
}

interface StateBase {
  order: Order;
  isSplitPayment: boolean;
  coPayingUsers: Pick<
    User,
    "id" | "displayName" | "firstName" | "lastName" | "profileImage"
  >[];
  paymentMethods: PaymentMethod[];
  selectedPaymentMethod: PaymentMethod | null;
  checkoutStatus: "idle" | "paymentPending" | "paymentCompleted";
  action: "" | "swishRedirectCallback";
}

interface EmptyState extends StateBase {
  orderType: "";
  dataStatus: "loading" | "error";
}

interface BookingState extends StateBase {
  orderType: "booking";
  bookingOrderItem: BookingOrderItem;
  booking: IBooking;
  dataStatus: "loaded";
}

interface MembershipState extends StateBase {
  orderType: "membership";
  membershipOrderItem: MembershipOrderItem;
  membership: Membership;
  dataStatus: "loaded";
}

interface EventState extends StateBase {
  orderType: "event";
  eventOrderItem: EventOrderItem;
  event: GeneralActivities;
  dataStatus: "loaded";
}

interface SeriesState extends StateBase {
  orderType: "series";
  seriesOrderItem: SeriesOrderItem;
  series: Series;
  dataStatus: "loaded";
}

interface OpenBookingState extends StateBase {
  orderType: "open-booking";
  openBookingOrderItem: OpenBookingOrderItem;
  booking: IBooking;
  dataStatus: "loaded";
}

type State =
  | EmptyState
  | BookingState
  | MembershipState
  | EventState
  | SeriesState
  | OpenBookingState;

type Action =
  | {
      type: "SET_IS_SPLIT_PAYMENT";
      isSplitPayment: State["isSplitPayment"];
      coPayingUsers?: State["coPayingUsers"];
    }
  | { type: "SET_PAYMENT_METHODS"; paymentMethods: State["paymentMethods"] }
  | {
      type: "SET_SELECTED_PAYMENT_METHOD";
      paymentMethod: State["selectedPaymentMethod"];
    }
  | { type: "SET_CHECKOUT_STATUS"; status: State["checkoutStatus"] }
  | {
      type: "SET_CO_PAYING_USERS";
      coPayingUsers: State["coPayingUsers"];
    }
  | {
      type: "SET_DATA_STATUS_ERROR";
    }
  | {
      type: "SET_BOOKING";
      bookingOrderItem: BookingOrderItem;
      booking: IBooking;
    }
  | {
      type: "SET_MEMBERSHIP";
      membershipOrderItem: MembershipOrderItem;
      membership: Membership;
    }
  | {
      type: "SET_EVENT";
      eventOrderItem: EventOrderItem;
      event: GeneralActivities;
    }
  | {
      type: "SET_SERIES";
      seriesOrderItem: SeriesOrderItem;
      series: Series;
    }
  | {
      type: "SET_OPEN_BOOKING";
      openBookingOrderItem: OpenBookingOrderItem;
      booking: IBooking;
    };

type Dispatch = (action: Action) => void;

const CheckoutContext = createContext<
  | {
      state: State;
      dispatch: Dispatch;
    }
  | undefined
>(undefined);

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_IS_SPLIT_PAYMENT": {
      return {
        ...state,
        isSplitPayment: action.isSplitPayment,
        coPayingUsers: action.coPayingUsers ?? [],
        selectedPaymentMethod: null,
      };
    }
    case "SET_PAYMENT_METHODS": {
      const selectedPaymentMethod = action.paymentMethods.find(
        method =>
          method.instrument === state.selectedPaymentMethod?.instrument &&
          method.provider === state.selectedPaymentMethod?.provider,
      );

      return {
        ...state,
        paymentMethods: action.paymentMethods,
        selectedPaymentMethod:
          selectedPaymentMethod?.status === PaymentMethodStatus.Enabled
            ? selectedPaymentMethod
            : null,
      };
    }
    case "SET_SELECTED_PAYMENT_METHOD": {
      return {
        ...state,
        selectedPaymentMethod: action.paymentMethod,
        checkoutStatus: action.paymentMethod ? state.checkoutStatus : "idle",
      };
    }
    case "SET_CHECKOUT_STATUS": {
      return {
        ...state,
        checkoutStatus: action.status,
      };
    }
    case "SET_CO_PAYING_USERS": {
      return {
        ...state,
        coPayingUsers: action.coPayingUsers,
        selectedPaymentMethod: null,
      };
    }
    case "SET_DATA_STATUS_ERROR": {
      if (state.orderType !== "") {
        return state;
      }

      return {
        ...state,
        dataStatus: "error",
      };
    }
    case "SET_BOOKING": {
      return {
        ...state,
        orderType: "booking",
        bookingOrderItem: action.bookingOrderItem,
        booking: action.booking,
        dataStatus: "loaded",
      };
    }
    case "SET_MEMBERSHIP": {
      return {
        ...state,
        orderType: "membership",
        membershipOrderItem: action.membershipOrderItem,
        membership: action.membership,
        dataStatus: "loaded",
      };
    }
    case "SET_EVENT": {
      return {
        ...state,
        orderType: "event",
        eventOrderItem: action.eventOrderItem,
        event: action.event,
        dataStatus: "loaded",
      };
    }
    case "SET_SERIES": {
      return {
        ...state,
        orderType: "series",
        seriesOrderItem: action.seriesOrderItem,
        series: action.series,
        dataStatus: "loaded",
      };
    }
    case "SET_OPEN_BOOKING": {
      return {
        ...state,
        orderType: "open-booking",
        openBookingOrderItem: action.openBookingOrderItem,
        booking: action.booking,
        dataStatus: "loaded",
      };
    }
    default: {
      // Used to catch unhandled actions
      const exhaustiveCheck: never = action;
      throw new Error(`Unhandled action: ${exhaustiveCheck}`);
    }
  }
};

const SUPPORTED_ACTIONS = ["swishRedirectCallback"];

interface CheckoutProviderProps {
  order: Order;
  action?: string;
}
export const CheckoutProvider = ({
  children,
  order,
  action,
}: React.PropsWithChildren<CheckoutProviderProps>) => {
  if (action && !SUPPORTED_ACTIONS.includes(action)) {
    throw new Error(`Unsupported action: ${action}`);
  }

  const initialState: EmptyState = {
    order,
    isSplitPayment: order.isSplittable,
    coPayingUsers: [],
    paymentMethods: [],
    selectedPaymentMethod: null,
    checkoutStatus: "idle",
    orderType: "",
    dataStatus: "loading",
    action: (action ?? "") as StateBase["action"],
  };

  if (initialState.action === "swishRedirectCallback") {
    console.log(
      "action 'swishRedirectCallback' found, setting selectedPaymentMethod",
    );
    initialState.selectedPaymentMethod = {
      provider: "SwedbankPay",
      instrument: "Swish",
      status: PaymentMethodStatus.Enabled,
    };
  }

  const orderType = useMemo(() => {
    const orderItemTypes = order.items.map(item => item.$type);

    switch (true) {
      case orderItemTypes.includes("booking"): {
        return "booking";
      }
      case orderItemTypes.includes("membership"): {
        return "membership";
      }
      case orderItemTypes.includes("event"): {
        return "event";
      }
      case orderItemTypes.includes("series"): {
        return "series";
      }
      case orderItemTypes.includes("open-booking"): {
        return "open-booking";
      }
      default: {
        dispatch({ type: "SET_DATA_STATUS_ERROR" });
      }
    }
  }, [order]);

  // Fetch and set data for a booking order
  useEffect(() => {
    if (orderType !== "booking") {
      return;
    }

    const getAndSetBookingData = async () => {
      const bookingOrderItem = order.items.find(
        (item): item is BookingOrderItem => item.$type === "booking",
      );

      if (!bookingOrderItem) {
        dispatch({ type: "SET_DATA_STATUS_ERROR" });
        return;
      }

      const booking = await getBooking(
        bookingOrderItem.bookingId,
        "individualprice,participants",
      )
        .then(response => response.data)
        .catch(() => dispatch({ type: "SET_DATA_STATUS_ERROR" }));

      if (!booking) {
        dispatch({ type: "SET_DATA_STATUS_ERROR" });
        return;
      }

      if (booking.participants.length < 1) {
        dispatch({ type: "SET_DATA_STATUS_ERROR" });
        return;
      }

      const participants = booking.participants.filter(
        participant => participant.id !== order.userId,
      );

      if (participants.length > 0) {
        dispatch({
          type: "SET_CO_PAYING_USERS",
          coPayingUsers: participants,
        });
      }

      dispatch({
        type: "SET_BOOKING",
        bookingOrderItem,
        booking,
      });
    };

    try {
      getAndSetBookingData();
    } catch (error) {
      console.error(error);
      dispatch({ type: "SET_DATA_STATUS_ERROR" });
    }
  }, [order.items, order.userId, orderType]);

  // Fetch and set data for a membership order
  useEffect(() => {
    if (orderType !== "membership") {
      return;
    }

    const getAndSetMembershipData = async () => {
      const membershipOrderItem = order.items.find(
        (item): item is MembershipOrderItem => item.$type === "membership",
      );

      if (!membershipOrderItem) {
        dispatch({ type: "SET_DATA_STATUS_ERROR" });
        return;
      }

      const membership = await getMembership(
        membershipOrderItem.membershipId,
      ).catch(() => dispatch({ type: "SET_DATA_STATUS_ERROR" }));

      if (!membership) {
        dispatch({ type: "SET_DATA_STATUS_ERROR" });
        return;
      }

      dispatch({
        type: "SET_MEMBERSHIP",
        membershipOrderItem,
        membership,
      });
    };

    try {
      getAndSetMembershipData();
    } catch (error) {
      console.error(error);
      dispatch({ type: "SET_DATA_STATUS_ERROR" });
    }
  }, [order.items, orderType]);

  // Fetch and set data for an event (general activity) order
  useEffect(() => {
    if (orderType !== "event") {
      return;
    }

    const getAndSetEventData = async () => {
      const eventOrderItem = order.items.find(
        (item): item is EventOrderItem => item.$type === "event",
      );

      if (!eventOrderItem) {
        dispatch({ type: "SET_DATA_STATUS_ERROR" });
        return;
      }

      const event = await getGeneralActivity(eventOrderItem.eventId, "")
        .then(response => response.data)
        .catch(() => dispatch({ type: "SET_DATA_STATUS_ERROR" }));

      if (!event) {
        dispatch({ type: "SET_DATA_STATUS_ERROR" });
        return;
      }

      dispatch({
        type: "SET_EVENT",
        eventOrderItem,
        event,
      });
    };

    try {
      getAndSetEventData();
    } catch (error) {
      console.error(error);
      dispatch({ type: "SET_DATA_STATUS_ERROR" });
    }
  }, [order.items, orderType]);

  // Fetch and set data for a series order
  useEffect(() => {
    if (orderType !== "series") {
      return;
    }

    const getAndSetSeriesData = async () => {
      const seriesOrderItem = order.items.find(
        (item): item is SeriesOrderItem => item.$type === "series",
      );

      if (!seriesOrderItem) {
        dispatch({ type: "SET_DATA_STATUS_ERROR" });
        return;
      }

      const series = await getSeries(seriesOrderItem.seriesId, "")
        .then(response => response.data)
        .catch(() => dispatch({ type: "SET_DATA_STATUS_ERROR" }));

      if (!series) {
        dispatch({ type: "SET_DATA_STATUS_ERROR" });
        return;
      }

      const teamMembers =
        series.registeredTeams
          .find(team =>
            team.teamMembers.some(member => member.id === order.userId),
          )
          ?.teamMembers.filter(member => member.id !== order.userId) ?? [];

      if (teamMembers.length < 1) {
        dispatch({
          type: "SET_DATA_STATUS_ERROR",
        });
        return;
      }

      dispatch({
        type: "SET_CO_PAYING_USERS",
        coPayingUsers: teamMembers,
      });

      dispatch({
        type: "SET_SERIES",
        seriesOrderItem,
        series,
      });
    };

    try {
      getAndSetSeriesData();
    } catch (error) {
      console.error(error);
      dispatch({ type: "SET_DATA_STATUS_ERROR" });
    }
  }, [order.items, order.userId, orderType]);

  // Fetch and set data for an open booking order
  useEffect(() => {
    if (orderType !== "open-booking") {
      return;
    }

    const getAndSetBookingData = async () => {
      const openBookingOrderItem = order.items.find(
        (item): item is OpenBookingOrderItem => item.$type === "open-booking",
      );

      if (!openBookingOrderItem) {
        dispatch({ type: "SET_DATA_STATUS_ERROR" });
        return;
      }

      const openBooking = await getOpenBooking(
        openBookingOrderItem.openBookingId,
      );

      const booking = await getBooking(
        openBooking.bookingId,
        "individualprice,participants",
      )
        .then(response => response.data)
        .catch(() => dispatch({ type: "SET_DATA_STATUS_ERROR" }));

      if (!booking) {
        dispatch({ type: "SET_DATA_STATUS_ERROR" });
        return;
      }

      if (booking.participants.length < 1) {
        dispatch({ type: "SET_DATA_STATUS_ERROR" });
        return;
      }

      const participants = booking.participants.filter(
        participant => participant.id !== order.userId,
      );

      if (participants.length > 0) {
        dispatch({
          type: "SET_CO_PAYING_USERS",
          coPayingUsers: participants,
        });
      }

      dispatch({
        type: "SET_OPEN_BOOKING",
        openBookingOrderItem,
        booking,
      });
    };

    try {
      getAndSetBookingData();
    } catch (error) {
      console.error(error);
      dispatch({ type: "SET_DATA_STATUS_ERROR" });
    }
  }, [order.facilityId, order.items, order.userId, orderType]);

  const [state, dispatch] = useReducer(reducer, initialState);

  usePaymentMethods(state, dispatch);

  return (
    <CheckoutContext.Provider value={{ state, dispatch }}>
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckout = () => {
  const context = useContext(CheckoutContext);

  if (context === undefined) {
    throw new Error(
      "useCheckoutContext must be used within a CheckoutProvider",
    );
  }

  return context;
};

export const useCheckoutState = () => {
  const { state } = useCheckout();

  return state;
};

export const useCheckoutDispatch = () => {
  const { dispatch } = useCheckout();

  return dispatch;
};

const ELIGBLE_CHECK_PAYMENT_METHODS = ["GooglePay", "ApplePay"] as const;

const usePaymentMethods = (state: State, dispatch: Dispatch) => {
  const toaster = useToaster();

  const swedbankPayEligbleCheckScriptLoadingStatus = useScript(
    process.env.REACT_APP_SWEBANK_PAY_ELIGBLE_CHECK_SCRIPT_URL ?? null,
  );

  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [swedbankPayAcceptedInstruments, setSwedbankPayAcceptedInstruments] =
    useState<SwedbankPayEligbleCheckInstrument[]>();

  useEffect(() => {
    if (paymentMethods.length < 1) {
      return;
    }

    // Filter payment methods that are not eligible for the check
    const filteredPaymentMethods = paymentMethods.reduce<PaymentMethod[]>(
      (acc, paymentMethod) => {
        // Dont filter payment methods that are not from SwedbankPay
        if (paymentMethod.provider !== "SwedbankPay") {
          acc.push(paymentMethod);
          return acc;
        }

        // Dont filter payment methods that are not eligible checked
        if (
          !ELIGBLE_CHECK_PAYMENT_METHODS.includes(
            paymentMethod.instrument as any,
          )
        ) {
          acc.push(paymentMethod);
          return acc;
        }

        for (const instrument of ELIGBLE_CHECK_PAYMENT_METHODS) {
          if (paymentMethod.instrument === instrument) {
            // Remove all eligble checked methods when the eligble check script could not be loaded
            if (swedbankPayEligbleCheckScriptLoadingStatus === "error") {
              continue;
            }

            // Set all eligble checked methods to loading when the eligble check script is not ready
            if (typeof swedbankPayAcceptedInstruments === "undefined") {
              acc.push({
                ...paymentMethod,
                status: PaymentMethodStatus.Loading,
              });
            } else if (swedbankPayAcceptedInstruments.includes(instrument)) {
              acc.push(paymentMethod);
            }
          }
        }

        return acc;
      },
      [],
    );

    dispatch({
      type: "SET_PAYMENT_METHODS",
      paymentMethods: filteredPaymentMethods,
    });
  }, [
    dispatch,
    paymentMethods,
    swedbankPayAcceptedInstruments,
    swedbankPayEligbleCheckScriptLoadingStatus,
  ]);

  useEffect(() => {
    if (swedbankPayEligbleCheckScriptLoadingStatus !== "ready") {
      return;
    }

    const timeout = setTimeout(async () => {
      if (!window.payex?.getAcceptedWallets) {
        return;
      }

      const acceptedWallets = await window.payex.getAcceptedWallets();

      setSwedbankPayAcceptedInstruments(acceptedWallets);
    }, 1_000);

    return () => clearTimeout(timeout);
  }, [swedbankPayEligbleCheckScriptLoadingStatus]);

  useEffect(() => {
    if (state.order.isPaid) {
      return;
    }

    if (state.dataStatus !== "loaded") {
      return;
    }

    const fetchPaymentMethods = async () => {
      try {
        const paymentMethods = await getOrderPaymentMethods(
          state.order.id,
          state.isSplitPayment,
        );
        setPaymentMethods(paymentMethods);
      } catch (error) {
        toaster.toastError.unknown();
      }
    };

    fetchPaymentMethods();
  }, [
    state.order.id,
    state.order.isPaid,
    state.dataStatus,
    state.isSplitPayment,
    dispatch,
    toaster.toastError,
  ]);
};
